import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import Slider_img from "../../Images/Top-Image.jpg";
import "./Cover.css";

export default function Cover(props) {
  return (
    <>
      <Container fluid className="p-0" id={props.id}>
        <Image src={Slider_img} className="slider-img"/>
        <Row noGutters="true">
          <Col  lg={{ offset: 4 }}>
            <h1 className="d-none d-lg-block">Empowering <br/> Artistic <br/> Talents </h1>
            <h1 className="d-lg-none px-0">Empowering<br/> Artistic Talents</h1>
            <p className="header-text d-none d-lg-block">Nabad is an innovative program that aims at <br/> empowering artists, arts organizations and <br/> creative enterprises in Southwestern Asia and <br/> North Africa to implement their artistic <br/> and cultural ideas, and market their artworks.</p>
          </Col>
        </Row>
      </Container>
      <p className="header-text d-lg-none">Nabad is an innovative program that aims at empowering artists, arts organizations and creative enterprises in Southwestern Asia and North Africa to implement their artistic and cultural ideas, and market their artworks.</p>
    </>
  );
}