import React from "react";
import { Col, Row } from "react-bootstrap";

import "./Heading.css";


export default function Heading() {
  return (
    <Row className="headings justify-content-center">
      <Col lg="auto">
        <h2>nabad emerged in response to the <br/> <span>august 4</span> port explosions in beirut </h2>
        <p className="paragraph">and strives to become a beacon of support in Southwestern Asia and North Africa's art communities.</p>
      </Col>
    </Row>
  );
}