import React from "react";
import { Col, Row } from "react-bootstrap";

import "./SixImagesRow.css";

export default function SixImagesRow(props) {
  return (
      <Row className="justify-content-lg-center" noGutters="true" id="goals-grid">
        <Col lg={4} sm={6} className={"six-columns-image "+props.dynamicClassCol1}>
          <p className="six-columns-text goals-text">
            GOALS
          </p>
        </Col>
        <Col lg={4} sm={6} className={"six-columns-image "+props.dynamicClassCol2}>
          <p className="six-columns-text">
          EMPOWERING EMERGING AND MARGINALIZED ARTISTIC VOICES
          </p>
        </Col>
        <Col lg={4} sm={6} className={"six-columns-image "+props.dynamicClassCol3}>
          <p className="six-columns-text">
          SUPPORTING LOCAL ART NGOs AND CREATIVE ENTERPRISES
          </p>
        </Col>
        <Col lg={4} sm={6} className={"six-columns-image "+props.dynamicClassCol4}>
          <p className="six-columns-text">
            FOSTERING ART-RELATED JOB OPPORTUNITIES
          </p>
        </Col>
        <Col lg={4} sm={6} className={"six-columns-image "+props.dynamicClassCol5}>
          <p className="six-columns-text">
            CREATING ARTS AND CULTURE CONTENT FOR AWARENESS
          </p>
        </Col>
        <Col lg={4} sm={6} className={"six-columns-image "+props.dynamicClassCol6}>
          <p className="six-columns-text">
            BUILDING ARTS AND CULTURE NETWORKS
          </p>
        </Col>
      </Row>
  );
}
