import React from "react";
import NavMenu from "../components/Menu/Menu";
import Cover from "../components/Cover/Cover";
import Heading from "../components/Headings/Heading";
import Title from "../components/Title/Title";
import { Container, Row, Image } from "react-bootstrap";
import TwoImageInfo from "../components/TwoImagesInfo/TwoImagesInfo";
import SixImagesRow from "../components/SixImagesRow/SixImagesRow";
import TextImageRow from "../components/TextImageRow/TextImageRow";
import HeroCall from "../components/HeroCall/HeroCall";
import ApplicationForm from "../components/ApplicationForm/ApplicationForm";
import Footer from "../components/Footer/Footer";
import "../styles/fonts.css";
import Image_brush from "../Images/Brush.jpg";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import { navigate } from "gatsby";
import favicon from "../Images/Logo.png";
import Video from "../components/Video/video";

export default function Home(props) {
  const [modalShow, setModalShow] = React.useState(false);
  function openForm() {
    setModalShow(true);
  }
  function openPage(link) {
    window.open(
      link,
      "_blank" // <- This is what makes it open in a new window.
    );
  }

  useEffect(() => {
    if (props.location.pathname === "/about") {
      navigate("/about");
      setTimeout(() => scrollTo("#about"), 1000);
    } else if (props.location.pathname === "/goals") {
      navigate("/goals");
      setTimeout(() => scrollTo("#goals-grid"), 1000);
    } else if (props.location.pathname === "/projects") {
      navigate("/projects");
      setTimeout(() => scrollTo("#projects"), 1000);
    }
  }, [props.location.pathname]);

  return (
    <>
      <Helmet>
        <title>Empowering Artistic Talent | Nabad.art</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <NavMenu location={props.location} />
      <Cover id="cover" />
      <Container>
        <Heading />
        <Row id="brush">
          <Image src={Image_brush} fluid />
        </Row>
        <Title title="Background" id="about" />
        <TwoImageInfo
          hideColTwo={true}
          image1="girl-photo.png"
          image2="man-drawing.png"
        >
          Nabad is an outgrowth of
          <a
            className="two-images-paragraph"
            href="https://www.daralkalima.edu.ps/en"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Dar al Kalima University College of Arts and Culture
          </a>{" "}
          which was established in 2006 in Bethlehem - Palestine. This
          university is the first institution of higher education in Palestine
          to focus its educational objectives on the performing arts, visual
          arts, and cultural heritage.
          <br />
          Based on its expertise generated over 15 years, Dar al Kalima started
          in 2019 an art intervention/outreach program in Gaza, and in 2020 the
          Nabad program in Beirut.
        </TwoImageInfo>
        <Title title="Vision" id="vision" />

        <TwoImageInfo
          hideColTwo={true}
          image1="artistic-heart.png"
          image2="heart.png"
        >
          Nabad is a heartbeat embedded with the larger beating heart of local
          and regional arts and culture life, a vital impulse of hope amid war,
          destruction and instability, and a driving pulsation towards social
          and community transformation.
        </TwoImageInfo>
        <Video
          videoSrcURL="https://www.youtube.com/embed/r1AS0snocqM"
          videoTitle="nabad"
        />
      </Container>
      <Container fluid className="p-0">
        <SixImagesRow
          dynamicClassCol1="goals"
          dynamicClassCol2="boy-art-image"
          dynamicClassCol3="girl-art-image"
          dynamicClassCol4="laptop-image"
          dynamicClassCol5="head-image"
          dynamicClassCol6="hands-image"
        />
      </Container>
      <Container id="projects">
        <Title title="Projects" />
        <TextImageRow
          firstColOrder={1}
          secondColOrder={2}
          image="meadow-art-therapy.png"
          firstLineHeading="MEADOWS"
          secondLineHeading="ART THERAPY"
          firstPar=" Meadows was officially established in Beirut in 2007 with the
            purpose of promoting the development of sustainable nature by means
            of social and cultural activities."
          buttonText="KNOW MORE"
          apply={() => openPage("http://www.meadowsngo.org/")}
        >
          Nabad is funding Meadows in 2020-2021 for the organization of art
          therapy workshops for nurses and medical staff at St George’s
          Hospital, and for art therapy training workshops that target young
          social workers and activists. These workshops aim at dealing with the
          traumatic effects resulting from the 4th of August Beirut port
          explosions.
        </TextImageRow>
        <TextImageRow
          carousel
          firstColOrder={2}
          secondColOrder={1}
          image="beirut-art-relief1-new.jpg"
          image1="beirut-art-relief2.jpg"
          image2="beirut-art-relief3.jpg"
          firstLineHeading="BEIRUT ART RELIEF"
          secondLineHeading="AND EMPOWERMENT"
          firstPar=" The 4th of August 2020 port of Beirut’s explosions left more than 200 dead, over 7000 injured, 300 000 homeless, and they wrecked an already fragile arts and culture scene. Major venues were either destroyed or partly damaged, but also small creative enterprises which play an important role in supporting their local communities. "
          buttonText=""
        >
          Nabad’s Beirut Art Relief and Empowerment project aims at supporting
          local small creative enterprises and art NGOs by addressing short-term
          and/or long-term needs, such as
          <a
            href="https://www.youtube.com/user/beirutjamsessions"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Beirut Jam Sessions,
          </a>
          <a
            href="https://havenforartists.org/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Haven for Artists
          </a>{" "}
          and
          <a href="https://www.plan-bey.com/ " target="_blank" rel="noreferrer">
            {" "}
            Plan Bey.
          </a>
        </TextImageRow>
        <TextImageRow
          video
          firstColOrder={1}
          secondColOrder={2}
          image="smoke-art.png"
          firstLineHeading="ARLEB"
          secondLineHeading=""
          firstPar="Arleb is a not-for-profit digital platform that was launched in February 2021 by Nabad. It aims at empowering artists in Lebanon by offering them an opportunity to showcase and sell their artworks to new audiences on an international level. This gallery seeks also to raise awareness about resilience & resistance art produced by emerging and marginalized artists."
          buttonText="VISIT ARLEB"
          apply={() => openPage("https://www.arleb.org/")}
        >
          Selected emerging and established artists which artworks were curated
          by a Beirut based team of visual artists, academics, designers and art
          directors, provide through Arleb a critical lens that educates,
          provokes, and holds a mirror to the Lebanese society, influencing what
          gets attention in the public sphere, addressing social, political and
          environmental issues through their artworks, and demonstrating their
          potency to building inclusive societies.
        </TextImageRow>
        <TextImageRow
          firstColOrder={2}
          secondColOrder={1}
          image="beriut-call.jpg"
          firstLineHeading="THE BEIRUT CALL: Harnessing Creativity"
          secondLineHeading=" for Change"
          firstPar=" The Beirut Call is Nabad’s project of a book on resilience & resistance arts in Lebanon, featuring Lebanese artists, activists, NGOs and creative enterprises’ testimonials, artworks, and initiatives for social, political and environmental change. "
          buttonText="Learn More"
          apply={() => openPage("https://elyssarpress.com/the-beirut-call/")}
        >
          The Beirut Call seeks to bring together those who know, those who do,
          and those who create to inspire new ways of thinking of and facing
          wars, crises, instability and despair.
          <br />
          Date of Publication: May 2021.
          <br /> Editors: Pamela Chrabieh and Roula Salibi.
          <br /> Contributors: Mitri Raheb, Pamela Chrabieh, Roula Salibi,
          Joelle Sfeir, Nada Raphael, Roula-Maria Dib, Roula Azar Douglas,
          Loulou Malaeb, Wadih Al-Asmar, Rabih Rached, Carmen Yahchouchi,
          Anthony Semaan, Omar Sabbagh, Nadia Wardeh, Katia Aoun Hage, Linda
          Tamim, Faten Yaacoub, Reine Abbas, Frank Darwiche, Dorine Potel, Cliff
          Makhoul.
          <br /> Publisher: Dar al Kalima University College of Arts and
          Culture.
          <br /> Design, Printing & Distribution:{" "}
          <a href=" https://elyssarpress.com/" target="_blank" rel="noreferrer">
            {" "}
            Elyssar Press, Redlands-USA.{" "}
          </a>
          Cover photo: Nada Raphael.
        </TextImageRow>

        <TextImageRow
          firstColOrder={1}
          secondColOrder={2}
          image="palart-about.jpg"
          firstLineHeading="Palart"
          secondLineHeading=""
          firstPar="PALART is an initiative by Dar AlKalima University that aims at
          promoting Palestinian Artist and their artwork globally aiming at
          greater visibility, recognition and featuring of excelling artists."
          buttonText="Visit Website"
          apply={() => openPage("https://www.palart.ps")}
        >
          The website also aims at promoting the Palestinian cause through the
          artwork produced by Palestinian Artist who usually depict elements of
          the Palestinian reality on the ground. The initiative comes in
          response to a rising demand for regional and global exposure from
          young Palestinian Artists. While each artist can develop his own
          social media handles to promote his/her work, PALART will be a central
          location featuring multiple Palestinian artists, their profiles,
          artist statements, artwork and products and links to their pages.
          PALART will also act as an online archive for current rising artists.
          The website will also facilitate the sales of Artwork in support of
          young Palestinian Artists.
        </TextImageRow>
        <Video
          videoSrcURL="https://www.youtube.com/embed/6xaPicjwdEU"
          videoTitle="Beirut Call"
        />
      </Container>

      {/* <HeroCall id="cta" apply={openForm} /> */}

      <Footer />

      <ApplicationForm
        openModal={openForm}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
