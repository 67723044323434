import React from "react";
import { Col, Row, Carousel } from "react-bootstrap";

import "./TextImageRow.css";

export default function TextImageRow({ children, ...props }) {
  return (
    <Row className="extra-row-padding justify-content-lg-center">
      <Col
        lg={6}
        sm={12}
        className={
          props.video
            ? "embed-responsive embed-responsive-16by9 order-lg-" +
              props.firstColOrder
            : "order-lg-" + props.firstColOrder
        }
      >
        {props.video ? (
          <iframe
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/5xZMtv41BOE"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        ) : props.carousel ? (
          <Carousel
            controls={false}
            indicators={false}
            pause={false}
            fade={true}
          >
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 img-fluid"
                src={require("../../Images/" + props.image)}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 img-fluid"
                src={require("../../Images/" + props.image1)}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100 img-fluid"
                src={require("../../Images/" + props.image2)}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        ) : (
          <img
            className="image-fit-col"
            src={require("../../Images/" + props.image)}
            alt=""
          ></img>
        )}
      </Col>
      <Col lg={6} sm={12} className={"order-lg-" + props.secondColOrder}>
        <h3 className="text-center text-lg-left">
          {props.firstLineHeading} <br />
          {props.secondLineHeading}
        </h3>
        <p className="text-center text-lg-left">{props.firstPar}</p>
        <p className="text-center text-lg-left">{children}</p>
        {props.buttonText.length > 0 && (
          <Row className="justify-content-center justify-content-lg-start">
            <Col lg="auto" xs="auto">
              <button className="text-image-button" onClick={props.apply}>
                {props.buttonText}
              </button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}
