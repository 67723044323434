import React from "react";
import { Col, Row } from "react-bootstrap";

import "./TwoImagesInfo.css";

export default function TwoImageInfo({ children, ...props }) {

  return (
    <>
      <Row className="two-images-row justify-content-lg-center" noGutters="true"> 
        <Col lg={6} className={props.hideColOne && "d-none d-lg-block"}>
          <img alt="" className="two-images" src={require("../../Images/" + props.image1)}></img>
        </Col>
        <Col lg={6} className={props.hideColTwo && "d-none d-lg-block"}>
          <img alt="" className="two-images" src={require("../../Images/" + props.image2)}></img>
        </Col>
      </Row>
      <Row className="two-images-paragraph">
        <Col>
          <p>{children}</p>
        </Col>
      </Row>
    </>
  );
}