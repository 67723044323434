import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";

import "./ApplicationForm.css";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object({
  firstName: yup
    .string()
    .min(3, "Too Short!")
    .required("First Name is Required"),
  lastName: yup.string().min(3, "Too Short!").required("Last Name is Required"),
  artCategory: yup
    .string()
    .min(3, "Too Short!")
    .required("An Art Category is Required"),
  shortBiography: yup
    .string()
    .min(3, "Too Short!")
    .required("Short Biography is Required"),
  statement: yup
    .string()
    .min(3, "Too Short!")
    .required("Statement is Required"),
  email: yup
    .string()
    .email("Enter a valid email address")
    .required("An Email is Required"),
  mobile: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("A Phone Number is Required"),
  website: yup.string(),
  digitalPortfolio: yup.string(),
  instagram: yup.string(),
  facebook: yup.string(),
  youtube: yup.string(),
});

export default function ApplicationForm(props) {
  useEffect(() => {
    if (window.location.pathname.includes("apply-now")) {
      props.openModal();
    }
  }, []);

  const [submited, setSubmited] = useState(true);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="purple-background">
        <Modal.Title id="contained-modal-title-vcenter" className="form-header">
          CALL FOR <br></br> <span>ARTISTS</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {submited && (
          <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
              const {
                firstName,
                lastName,
                artCategory,
                shortBiography,
                statement,
                email,
                mobile,
                website,
                digitalPortfolio,
                instagram,
                facebook,
                youtube,
              } = values;
              axios
                .post(`https://nabad.art/beta/submit.php`, {
                  firstName: firstName,
                  lastName: lastName,
                  artCategory: artCategory,
                  shortBiography: shortBiography,
                  statement: statement,
                  email: email,
                  mobile: mobile,
                  website: website,
                  digitalPortfolio: digitalPortfolio,
                  instagram: instagram,
                  facebook: facebook,
                  youtube: youtube,
                })
                .then((res) => {
                  console.log(res);
                  setSubmited(false);
                });
            }}
            initialValues={{
              firstName: "",
              lastName: "",
              artCategory: "",
              shortBiography: "",
              statement: "",
              email: "",
              mobile: "",
              website: "",
              digitalPortfolio: "",
              instagram: "",
              facebook: "",
              youtube: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                  <Col lg={12} align="center">
                    <p className="text-center form-paragraph">
                      Deadline to fill this form is January 30, 2021. Selected
                      artists will be contacted for further information about
                      the submission guidelines and usage rights. Note that
                      there are no submission fees.
                    </p>
                  </Col>
                </Form.Row>

                <Form.Group controlId="fName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    isInvalid={touched.firstName && errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="lName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    isInvalid={touched.lastName && errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="artCategory">
                  <Form.Label>Art Category</Form.Label>
                  <Form.Control
                    type="text"
                    name="artCategory"
                    value={values.artCategory}
                    onChange={handleChange}
                    isInvalid={touched.artCategory && errors.artCategory}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.artCategory}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="shortBiography">
                  <Form.Label>Short Biography</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="shortBiography"
                    onChange={handleChange}
                    isInvalid={touched.shortBiography && errors.shortBiography}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.shortBiography}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="Statement">
                  <Form.Label>Statement</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="statement"
                    onChange={handleChange}
                    isInvalid={touched.statement && errors.statement}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.statement}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="Email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                    isInvalid={touched.email && errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="mobileNumber">
                  <Form.Label>Mobile Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    name="mobile"
                    onChange={handleChange}
                    isInvalid={touched.mobile && errors.mobile}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="Website">
                  <Form.Label>Website / Blog (Optional)</Form.Label>
                  <Form.Control
                    type="url"
                    name="website"
                    onChange={handleChange}
                    isInvalid={touched.website && errors.website}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.website}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="digitalPortfolio">
                  <Form.Label>Digital Portfolio (Optional)</Form.Label>
                  <Form.Control
                    type="url"
                    name="digitalPortfolio"
                    onChange={handleChange}
                    isInvalid={
                      touched.digitalPortfolio && errors.digitalPortfolio
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.digitalPortfolio}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="socialMedia">
                  <Form.Label>Social Media Channels (Optional)</Form.Label>
                  <Form.Control
                    type="url"
                    name="instagram"
                    placeholder="Instagram"
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="url"
                    name="facebook"
                    placeholder="Facebook"
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="url"
                    name="youtube"
                    placeholder="Youtube"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Row>
                  <Col align="center">
                    <Form.Control type="submit" value="Apply Now" />
                  </Col>
                </Form.Row>
              </Form>
            )}
          </Formik>
        )}
        {!submited && (
          <Row>
            <Col lg={6}>
              <p className="font-20px my-3 form-paragraph">
                Thank you for applying. We will contact you shortly.
              </p>
            </Col>
            <Col lg={6} align="center">
              <button className="close-button" onClick={props.onHide}>
                Back to the Website
              </button>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}
