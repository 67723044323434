import React from "react";
import { Col, Row } from "react-bootstrap";

import "./Title.css" 


export default function Title(props) {
  
  return (
    <Row className="title justify-content-center" id={props.id}>
      <Col lg="auto">
          <h2 className="title-text">{props.title}</h2>
      </Col>
    </Row>
  );
}
