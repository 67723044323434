import React from "react";
import { Row, Col } from "react-bootstrap";

export default function Video({ videoSrcURL, videoTitle }) {
  return (
    <>
      <Row className="pt-0 pb-5 ">
        <Col className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src={videoSrcURL}
            title={videoTitle}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Col>
      </Row>
    </>
  );
}