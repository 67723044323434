import React, { useState } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import Logo from "../../Images/Logo.png";
import "./Menu.css";
import scrollTo from "gatsby-plugin-smoothscroll";

export default function NavMenu() {
  const [ToggleMenu, setMenuToggle] = useState(false);

  function handleClick(target, url) {
    setMenuToggle(false);
    scrollTo(`#${target}`);
    window.history.pushState("Object", "Title", `/${url}`);
  }

  return (
    <>
      <Navbar sticky="top" bg="" variant="" className="menu-row">
        <Navbar.Brand href="/#cover">
          <img
            src={Logo}
            className="d-inline-block align-middle image-div"
            alt="Nabad Logo"
          ></img>
        </Navbar.Brand>
        <Nav className="d-none d-lg-flex ml-auto nav-container">
          <Nav.Item>
            <Button
              variant="link"
              className="link"
              onClick={() => handleClick("cover", "")}
            >
              Home
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              variant="link"
              className="link"
              onClick={() => handleClick("about", "about")}
            >
              About
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              variant="link"
              className="link"
              onClick={() => handleClick("goals-grid", "goals")}
            >
              Goals
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              variant="link"
              className="link"
              onClick={() => handleClick("projects", "projects")}
            >
              Projects
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              variant="link"
              href="mailto:hello@nabad.art"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              Contact
            </Button>
          </Nav.Item>
        </Nav>
        <div
          className="d-block d-lg-none toggler"
          onClick={() => setMenuToggle(!ToggleMenu)}
          onKeyDown={() => setMenuToggle(!ToggleMenu)}
          role="button"
          tabIndex="0"
          aria-label="Mobile Menu Button"
        ></div>
      </Navbar>
      <Menu
        isOpen={ToggleMenu}
        width={"60%"}
        customBurgerIcon={false}
        right
        menuClassName={"mobile-menu d-lg-none"}
        itemListClassName={
          "d-flex flex-column justify-content-center height-90"
        }
      >
        <Button
          variant="link"
          onClick={() => handleClick("cover", "")}
          className="link my-1"
        >
          Home
        </Button>

        <Button
          variant="link"
          onClick={() => handleClick("about", "about")}
          className="link my-1"
        >
          About
        </Button>

        <Button
          variant="link"
          onClick={() => handleClick("goals-grid", "goals")}
          className="link my-1"
        >
          Goals
        </Button>

        <Button
          variant="link"
          onClick={() => handleClick("projects", "projects")}
          className="link my-1"
        >
          Projects
        </Button>

        <Button
          variant="link"
          href="mailto:hello@nabad.art"
          className="link"
          target="_blank"
          rel="noreferrer"
          onClick={() => setMenuToggle(false)}
        >
          Contact
        </Button>
      </Menu>
    </>
  );
}
