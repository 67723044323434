import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Facebook from "../../Images/facebook.png";
import Instagram from "../../Images/instagram.png";
// import Youtube from "../../Images/youtube.png";
// import Email from "../../Images/email.png";

import "./Footer.css";

export default function Footer(props) {
  return (
    <Container fluid>
      <Row className=" justify-content-center footer-second-section">
        <Col
          lg={6}
          className="pr-0 pl-0 align-vertical text-center text-lg-left"
        >
          <p className="rights-par display-inline">
            © 2020-2021 Nabad. All Rights Reserved.
          </p>
        </Col>
        <Col
          lg={6}
          className="pr-0 pr-lg-3 pl-0 align-vertical text-center text-lg-right"
        >
          {/* <a
            href="mailto:hello@nabad.art"
            className="nabad-link display-inline"
          >
            <img
            src={Email}
            alt="email logo"
            className="padding-10px ico"
          ></img>
          </a> */}
          Follow us:
          <a href="https://www.facebook.com/nabad.art/" target="_blank" rel="noreferrer">
            <img
              className="padding-10px ico"
              src={Facebook}
              alt="facebook logo"
            ></img>
          </a>
          <a href="https://www.instagram.com/nabad.art/" target="_blank" rel="noreferrer">
            <img
              className="padding-10px ico"
              src={Instagram}
              alt="instagram logo"
            ></img>
          </a>
          {/* <a href="#">
            <img
              className="padding-10px"
              src={Youtube}
              alt="youtube logo"
            ></img>
          </a> */}
        </Col>
      </Row>
    </Container>
  );
}
